import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { MAIN_URL } from "../config/constants";

const GuestGuard = ({ children }) => {
  const { isLoggedIn } = useSelector((state) => state?.auth);

  if (isLoggedIn) {
    return <Redirect to={MAIN_URL} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
