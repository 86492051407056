import { Box, List, Drawer, Divider, Typography } from "@mui/material/";
import makeStyles from "@mui/styles/makeStyles";
import DrawerEntry from "./DrawerEntry";
import { MenuItems } from "config/drawer-items";
import CustomDrawerHeader from "./CustomDrawerHeader";
import { COMPANY_NAME, DRAWER_WIDTH } from "config/constants";
import ThemeColors from "config/colors";

const useStyles = makeStyles(() => ({
  drawer: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
    left: 0,
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      backgroundColor: "#d3d3d3",
      boxShadow: "inset 0 0 4px #7e7e7e",
      width: "0.7rem",
      borderRadius: 8,
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: 8,
      backgroundColor: "#7e7e7e",
      minHeight: 24,
    },
    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
      backgroundColor: "#646464",
    },
    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
      backgroundColor: "#646464",
    },
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
      // backgroundColor: ThemeColors.brandingRed,
      backgroundColor: "#646464",
    },
    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
      backgroundColor: "#646464",
    },
  },
}));

const DrawerBottom = () => {
  return (
    <Box
      className="d-flex justify-content-center align-items-center p-2 mb-4"
      dir="ltr"
    >
      <Typography variant="body2" className="px-2">
        Powered by
      </Typography>

      <img
        width="52"
        height="20"
        alt={COMPANY_NAME}
        src={process.env.PUBLIC_URL + "/assets/svgs/logo.png"}
      />
      <Typography variant="subtitle2" className="px-2">
        {process.env.REACT_APP_VERSION}
      </Typography>
    </Box>
  );
};

const CustomDrawer = ({ isDrawerOpen, handleDrawerToggle, history }) => {
  const classes = useStyles();

  const drawer = (
    <div className="h-100 d-flex flex-column justify-content-between drawer">
      <div>
        <CustomDrawerHeader />
        <Divider />
        <List component="nav">
          {MenuItems?.map((item) => (
            <DrawerEntry history={history} key={item.id} item={item} />
          ))}
        </List>
        <Divider />
      </div>
      <DrawerBottom />
    </div>
  );

  return (
    <nav className={classes.drawer}>
      <Drawer
        variant="persistent"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {drawer}
      </Drawer>
    </nav>
  );
};

export default CustomDrawer;
