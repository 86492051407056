import {
  Box,
  Grid,
  Menu,
  Avatar,
  MenuItem,
  IconButton,
  Typography,
  Divider,
} from "@mui/material/";
import { useState } from "react";
import ThemeColors from "config/colors";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import BaseURLDialog from "components/BaseURLDialog";
import TenantSelector from "components/TenantSelector";
import LocalizationIcon from "components/LocalizationIcon";
import CodeRoundedIcon from "@mui/icons-material/CodeRounded";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import LockResetRoundedIcon from "@mui/icons-material/LockResetRounded";

const AppBarUserIcon = ({ handleLogout, history }) => {
  const { t } = useTranslation();
  const [baseURL, setBaseURL] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tenantSelectorOpen, setTenantSelectorOpen] = useState(false);
  const open = Boolean(anchorEl);

  const { fullName, selectedTenant } = useSelector(
    (state) => state?.auth?.user
  );

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenTenantSelector = () => {
    handleClose();
    setTenantSelectorOpen(true);
  };
  const handleChangeBaseURL = () => {
    handleClose();
    setBaseURL(true);
  };

  const handleTenantSelectorClose = () => {
    setTenantSelectorOpen(false);
  };

  return (
    <div
      align="right"
      className="d-flex align-items-center justify-content-center"
    >
      <Box>
        <Typography variant="body1" color="textPrimary">
          {fullName}
        </Typography>
        <Typography variant="body2" style={{ color: ThemeColors.brandingRed }}>
          {selectedTenant?.label}
        </Typography>
      </Box>

      <IconButton
        size="large"
        color="inherit"
        aria-label="Account"
        onClick={handleMenu}
        aria-haspopup="true"
        aria-controls="menu-appbar"
      >
        <Avatar />
      </IconButton>
      <LocalizationIcon />
      <Menu
        keepMounted
        className="mt-5"
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleOpenTenantSelector} disableRipple>
          <Grid container>
            <Grid item xs={3}>
              <RepeatOutlinedIcon sx={{ color: ThemeColors.brandingRed }} />
            </Grid>
            <Grid item xs={9}>
              <Typography className="mx-1 mr-2">{t("Switch Site")}</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={() => history.push("/reset-password")} disableRipple>
          <Grid container>
            <Grid item xs={3}>
              <LockResetRoundedIcon sx={{ color: ThemeColors.brandingRed }} />
            </Grid>
            <Grid item xs={9}>
              <Typography className="mx-1 mr-2">
                {t("Reset Password")}
              </Typography>
            </Grid>
          </Grid>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout} disableRipple>
          <Grid container>
            <Grid item xs={3}>
              <LogoutOutlinedIcon sx={{ color: ThemeColors.brandingRed }} />
            </Grid>
            <Grid item xs={9}>
              <Typography className="mx-1 mr-2">{t("Logout")}</Typography>
            </Grid>
          </Grid>
        </MenuItem>
        {process.env.REACT_APP_ENV === "dev" && (
          <MenuItem onClick={handleChangeBaseURL}>
            <Grid container>
              <Grid item xs={3}>
                <CodeRoundedIcon sx={{ color: ThemeColors.brandingRed }} />
              </Grid>
              <Grid item xs={9}>
                <Typography className="mx-1 mr-2">Base URLs</Typography>
              </Grid>
            </Grid>
          </MenuItem>
        )}
      </Menu>
      {tenantSelectorOpen && (
        <TenantSelector
          history={history}
          open={tenantSelectorOpen}
          onClose={handleTenantSelectorClose}
        />
      )}
      {baseURL && (
        <BaseURLDialog open={baseURL} handleClose={() => setBaseURL(false)} />
      )}
    </div>
  );
};

export default AppBarUserIcon;
