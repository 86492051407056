import axios, { axios_Identity, simpleErrorHandling } from "config/network";
import { TenantsData } from "models/TenantSettings";

// const login = async (username, password) => {
//   try {
//     const response = await axios.post("/account/login", {
//       username,
//       password,
//     });
//     const result = response.data;

//     return result;
//   } catch (err) {
//     if (err.message) throw err;
//     else {
//       throw new Error(err);
//     }
//   }
// };

const login = async (username, password) => {
  try {
    const response = await axios_Identity.post("/api/Authentication/GetToken", {
      userName: username,
      password,
    });
    const result = response.data;

    return result?.data;
  } catch (err) {
    simpleErrorHandling(err, "Failed to login");
  }
};

const getNewAccessToken = async (refreshToken) => {
  try {
    const response = await axios_Identity.post(
      "/api/Authentication/GetNewAccessToken",
      { refreshToken }
    );
    const { data } = response;
    return data?.data;
  } catch (err) {
    simpleErrorHandling(err, "Failed to get new token please login");
  }
};

const resetPassword = async (body) => {
  try {
    await axios_Identity.post(`/api/Authentication/ChangePassword`, body);
    return true;
  } catch (err) {
    simpleErrorHandling(err, "Failed to reset password");
  }
};

const ValidateTenants = async (siteId, body) => {
  try {
    let formData = new FormData();
    formData.append("file", body);

    const response = await axios_Identity.post(
      `/api/Tenant/ValidateUploadBulkTenants`,
      formData
    );

    return response;
  } catch (err) {
    simpleErrorHandling(err, "Failed to upload file");
  }
};
const AddTenants = async (siteId, body) => {
  try {
    const response = await axios_Identity.post(
      `/api/Tenant/Site/${siteId}/AddTenants`,
      body
    );
    return true;
  } catch (err) {
    simpleErrorHandling(err, "Failed to upload file");
  }
};
const editTenant = async (body) => {
  try {
    const response = await axios_Identity.patch(`/api/Tenant/EditTenant`, body);
    const { data } = response;
    if (data?.code === 1) {
      return true;
    } else {
      throw new Error(data?.message);
    }
  } catch (err) {
    simpleErrorHandling(err, "Failed to edit tenant");
  }
};
const tenantSettings = async (tenantId) => {
  try {
    const response = await axios.get(
      `/api/TenantSettings/${tenantId}/GetTenantSetting`
    );
    const { data } = response.data;

    return data.map((e) => TenantsData.fromSetting(e));
  } catch (err) {
    simpleErrorHandling(err, "Failed to edit Tenant");
  }
};
const UpdateSettings = async (tenantId, body) => {
  try {
    const response = await axios.put(
      `/api/TenantSettings/${tenantId}/UpdateTenantSettings`,
      {
        tenantId,
        Settings: body,
      }
    );
    return true;
  } catch (err) {
    simpleErrorHandling(err, "Failed to update settings");
  }
};
// get tenants
const getTenants = async () => {
  try {
    const response = await axios_Identity.get(
      `/api/Tenant/GetTenantsUnderUserManagement`
    );
    const { data } = response.data;
    return data;
  } catch (err) {
    simpleErrorHandling(err, "Failed to get Tenants");
  }
};

const userChooseOrSwitchTenant = async (
  tenantId,
  selectTenantFirstTime = false,
  tempToken
) => {
  try {
    if (selectTenantFirstTime && tempToken) {
      const response = await axios_Identity.post(
        `/api/Authentication/switch-tenant/${tenantId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${tempToken}`,
          },
        }
      );
      const result = response.data;

      return result?.data;
    } else {
      const response = await axios_Identity.post(
        `/api/Authentication/switch-tenant/${tenantId}`
      );
      const result = response.data;

      return result?.data;
    }
  } catch (err) {
    simpleErrorHandling(err, "Failed to select tenant");
  }
};
export const GeneralService = {
  login,
  editTenant,
  AddTenants,
  resetPassword,
  tenantSettings,
  UpdateSettings,
  ValidateTenants,
  getTenants,
  getNewAccessToken,
  userChooseOrSwitchTenant,
};
