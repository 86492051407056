import Cookies from "js-cookie";
import ThemeColors from "./colors";
import languages from "../localization/Languages";
import {createTheme, adaptV4Theme} from "@mui/material/styles";

const {breakpoints} = createTheme(adaptV4Theme({}));

const typograpghy = {
    h1: {
        fontSize: "40px !important",
        fontWeight: 900 + " !important",
        [breakpoints.down("md")]: {
            fontSize: "30px !important",
        },
    },
    h2: {
        fontSize: "36px !important",
        fontWeight: 900 + " !important",
        color: ThemeColors.redColor,
        [breakpoints.down("md")]: {
            fontSize: "30px !important",
        },
    },
    h3: {
        fontSize: "24px !important",
        fontWeight: 800 + " !important",
        [breakpoints.down("md")]: {
            fontSize: "22px !important",
        },
    },
    h4: {
        fontSize: "22px !important",
        fontWeight: 700 + " !important",
        [breakpoints.down("md")]: {
            fontSize: "20px !important",
        },
    },
    h5: {
        fontSize: "20px !important",
        fontWeight: 600 + " !important",
        [breakpoints.down("md")]: {
            fontSize: "18px !important",
        },
    },
    h6: {
        fontSize: "18px !important",
        fontWeight: 500 + " !important",
        [breakpoints.down("md")]: {
            fontSize: "16px !important",
        },
    },
    body1: {
        fontSize: "16px !important",
        fontWeight: 500 + " !important",
        [breakpoints.down("md")]: {
            fontSize: "14px !important",
        },
    },
    body2: {
        fontSize: "14px !important",
        fontWeight: 400 + " !important",
        [breakpoints.down("md")]: {
            fontSize: "12px !important",
        },
    },
    caption: {
        fontSize: "12px !important",
        fontWeight: 300 + " !important",
        [breakpoints.down("md")]: {
            fontSize: "10px !important",
        },
    },
    subtitle1: {
        fontSize: "10px !important",
        fontWeight: 300 + " !important",
        textDecoration: "underline",
        [breakpoints.down("md")]: {
            fontSize: "10px !important",
        },
    },
    subtitle2: {
        fontSize: "8px !important",
        fontWeight: 300 + " !important",
        [breakpoints.down("md")]: {
            fontSize: "10px !important",
        },
    },
    button: {
        color: ThemeColors.blueColor,
        borderRadius: 30,
        textTransform: "none",
        fontSize: 16,
        [breakpoints.down("md")]: {
            fontSize: 14,
        },
    },
};
const currLangCode = Cookies.get("i18next") || "en";
const currLang = languages.find((l) => l.code === currLangCode);

const direction = currLang.dir || "ltr";

const theme = createTheme(
        adaptV4Theme(
            // arEG
            {
                direction: direction,
                overrides: {
                    MuiDialogTitle: {root: {height: "50px"}},
                    MuiDialog: {
                        paper: {
                            borderRadius: "10px/10px",
                        },
                    },
                    MuiTab: {
                        root: {
                            borderRadius: "10px/10px",
                        },
                        wrapped: {
                            borderRadius: "10px/10px",
                        },
                        fullWidth: {
                            borderRadius: "10px/10px",
                        },
                    },
                    MuiCard: {
                        root: {
                            borderRadius: "10px/10px",
                            backgroundColor: "#fafafa",
                        },
                    },
                    MuiCssBaseline: {
                        "@global": {
                            "@fontFace": ["Gilroy"],
                            div: {
                                "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                                    backgroundColor: "#a9a9a95f",
                                    width: "12px",
                                },
                                "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                                    borderRadius: 8,
                                    backgroundColor: "#4d50517d",
                                },
                            },
                        },
                    },
                    MuiFormHelperText: {
                      root: {
                          textAlign: direction === 'rtl' ? 'right': 'left'
                      },
                    },
                    MuiTextField: {
                        root: {
                            "& .MuiInputBase-root": {
                                background: ThemeColors.fieldColor,
                            },
                            ".Mui-disabled": {
                                "-webkit-text-fill-color": ThemeColors.fieldDisabledColor,
                            },
                            label: {
                                transformOrigin: direction === 'rtl' ? 'right': 'left',
                                right: '1.75rem',
                            },
                            fieldset: {
                                textAlign: direction === 'rtl' ? 'right': 'left'
                            }
                        },
                    },
                    MuiAutocomplete: {
                        inputRoot: {
                            background: ThemeColors.fieldColor,
                        }
                        ,
                    }
                    ,
                    MuiButton: {
                        text: {
                            fontSize: 16,
                        }
                        ,
                    }
                    ,
                    MuiTypography: {
                        ...
                            typograpghy,
                    }
                    ,
                },
                palette: {
                    start: {
                        main: "#777880",
                        background:
                            "#ffedd2",
                        contrastText:
                            "#ff9800",
                    }
                    ,
                    create: {
                        main: "#777880",
                        background:
                            "#f3ebf7",
                        contrastText:
                            "#8b6ca2",
                    }
                    ,
                    cancel: {
                        main: "#777880",
                        background:
                            "#fee3e3",
                        contrastText:
                            "#ef5350",
                    }
                    ,
                    count: {
                        main: "#777880",
                        background:
                            "#e2f5ff",
                        contrastText:
                            "#00a7ff",
                    }
                    ,
                    complete: {
                        main: "#777880",
                        background:
                            "#dbfde0",
                        contrastText:
                            "#27b85a",
                    }
                    ,
                    foc: {
                        // background: "#fff6ed9f",
                        // contrastText: "#ffa24b",
                        main: "#20B2AA",
                        background:
                            "#e2f9f7",
                        contrastText:
                            "#3FBDB6",
                    }
                    ,

                    primary: {
                        main: ThemeColors.blueColor,
                        contrastText:
                        ThemeColors.whiteColor,
                    }
                    ,
                    secondary: {
                        main: ThemeColors.redColor,
                        contrastText:
                        ThemeColors.whiteColor,
                    }
                    ,
                    error: {
                        main: ThemeColors.lightRedColor,
                    }
                    ,
                    text: {
                        primary: ThemeColors.blackColor,
                        secondary:
                        ThemeColors.greyColor,
                        hint:
                        ThemeColors.lightGreyColor,
                        disabled:
                        ThemeColors.lightBlueColor,
                    }
                    ,
                    background: {
                        default:
                            ThemeColors.backgroundColor + " !important",
                        paper:
                        ThemeColors.backgroundPaperColor,
                        backgroundField:
                        ThemeColors.greyBgFieldColor
                    }
                    ,
                    action: {
                        active: ThemeColors.greyColor,
                        disabled:
                        ThemeColors.lightGreyColor,
                    }
                    ,

                }
                ,
                typography: {
                    fontFamily: "Gilroy",
                    ...
                        typograpghy,
                }
                ,
            }
        )
    )
;

export default theme;
