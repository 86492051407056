import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  ListSubheader,
} from "@mui/material/";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import ThemeColors from "config/colors";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const DrawerEntry = ({ history, item, nested = false }) => {
  const classes = makeStyles((theme) => ({
    nested: {
      paddingLeft: theme.spacing(5),
    },
    iconMargin: {
      marginRight: "-18px",
    },
  }))();

  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClick = (item) => {
    if (item?.type === "item" && !isTabSelected(item)) {
      history?.push(item.url);
    } else if (item?.type === "collapse") {
      setOpen(!open);
    }
  };

  const isTabSelected = (item) => {
    return window?.location?.pathname === item?.url;
  };

  const userPermissions = useSelector(
    (state) => state?.auth?.user?.permissions ?? []
  );

  // check if the user got this permission only if the component requires a permission
  const showDrawerItem = () => {
    if (item?.permissions && item?.permissions?.length > 0) {
      let result = false;
      for (const i in item?.permissions) {
        for (const j in userPermissions) {
          result |= item?.permissions[i] === userPermissions[j];
        }
      }
      return result;
    }

    return true;
  };

  if (showDrawerItem()) {
    return (
      <>
        {item?.type !== "title" && (
          <ListItem
            //TODO: Remove
            button
            sx={{ textAlign: "inherit" }}
            selected={isTabSelected(item)}
            onClick={() => handleClick(item)}
            className={nested ? classes.nested : ""}
          >
            {item?.icon && (
              <ListItemIcon
                className={classes.iconMargin}
                style={{ color: ThemeColors.brandingRed }}
              >
                {item?.icon}
              </ListItemIcon>
            )}
            <ListItemText primary={t(item.title)} />
            {item?.type === "collapse" && (
              <>{open ? <ExpandLess /> : <ExpandMore />}</>
            )}
          </ListItem>
        )}

        {item?.type === "title" && (
          <List
            component="div"
            subheader={
              item?.title && (
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  style={{
                    color: ThemeColors.darkBlue,
                    fontWeight: 700,
                    position: "inherit",
                  }}
                >
                  {t(item?.title)}
                </ListSubheader>
              )
            }
            disablePadding
          >
            {item?.children?.map((item, index) => (
              <DrawerEntry history={history} key={index} item={item} />
            ))}
          </List>
        )}

        {item?.type === "collapse" && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List
              component="div"
              subheader={
                item?.subheader && (
                  <ListSubheader component="div" id="nested-list-subheader">
                    {item?.subheader}
                  </ListSubheader>
                )
              }
              disablePadding
            >
              {item?.children?.map((item, index) => (
                <DrawerEntry history={history} key={index} item={item} nested />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export default DrawerEntry;
