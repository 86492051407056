const fromJSON = (data) => {
  let result = {
    logo: data?.logo,
    permissions: data?.permissions,
    name: data?.tenantName || data?.name,
    id: data?.gln || data?.tenantId || data?.id,
  };

  result["label"] = result.name + " (" + result.id + ")";

  return result;
};

const toMap = (data) => ({
  name: data?.tenantName,
  id: data?.gln,
});

export const Tenant = {
  toMap,
  fromJSON,
};
