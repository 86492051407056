import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import "styles/index.css";
import { store, persistor } from "redux/store";
import theme from "config/theme";
import routes from "config/routes";
import { Provider } from "react-redux";
import Toaster from "components/Toaster";
import { jss } from "localization/index";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StylesProvider } from "@mui/styles";
import RoutesBuilder from "components/routesBuilder";
import SessionWrapper from "components/SessionWrapper";
import CustomBackdrop from "components/CustomBackdrop";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

// see Deprecated ** Remove <StylesProvider jss={jss}>
function App() {
  return (
    <StyledEngineProvider injectFirst>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <SessionWrapper>
                  <Router>
                    <RoutesBuilder routes={routes} />
                  </Router>
                  <Toaster />
                  <CustomBackdrop />
                </SessionWrapper>
              </PersistGate>
            </Provider>
          </LocalizationProvider>
        </ThemeProvider>
      </StylesProvider>
    </StyledEngineProvider>
  );
}

export default App;
