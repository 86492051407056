import { useDispatch } from "react-redux";
import { addBackdrop, hideBackdrop } from "redux/slices/backdropSlice";
import {
  addNotification,
  hideNotification,
} from "redux/slices/notificationSlice";

export const useNotificationAndBackdrop = () => {
  const dispatch = useDispatch();

  const displayNotification = (notificationBody) => {
    dispatch(addNotification(notificationBody));
  };
  const clearNotification = () => {
    dispatch(hideNotification());
  };
  const displayBackdrop = () => {
    dispatch(addBackdrop());
  };
  const clearBackdrop = () => {
    dispatch(hideBackdrop());
  };
  return {
    displayNotification,
    clearNotification,
    displayBackdrop,
    clearBackdrop,
  };
};
