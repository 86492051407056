import { createSlice } from "@reduxjs/toolkit";
import axios, { axios_Identity, axios_Reports } from "config/network";

const timeZoneOffset = new Date().getTimezoneOffset() * -1;

const initialState = {
  isLoggedIn: false,
  token: null,
  refreshToken: null,
  user: null,
};

const setSession = (token, tenantId) => {
  if (token) {
    axios.defaults.headers.common["TenantId"] = tenantId;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    axios.defaults.headers.common["TimeZoneOffset"] = timeZoneOffset;
    axios_Reports.defaults.headers.common["TenantId"] = tenantId;
    axios_Reports.defaults.headers.common.Authorization = `Bearer ${token}`;
    axios_Reports.defaults.headers.common["TimeZoneOffset"] = timeZoneOffset;
    axios_Identity.defaults.headers.common["TenantId"] = tenantId;
    axios_Identity.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["TenantId"];
    delete axios.defaults.headers.common.Authorization;
    delete axios.defaults.headers.common["TimeZoneOffset"];
    delete axios_Reports.defaults.headers.common["TenantId"];
    delete axios_Reports.defaults.headers.common.Authorization;
    delete axios_Reports.defaults.headers.common["TimeZoneOffset"];
    delete axios_Identity.defaults.headers.common["TenantId"];
    delete axios_Identity.defaults.headers.common.Authorization;
  }
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // logIn(state, action) {
    //   const { token, user } = action.payload;
    //   console.log("in logIn");
    //   setSession(token, user?.selectedTenant?.id);

    //   state.isLoggedIn = true;
    //   state.token = token;
    //   // state.refreshToken = refreshToken;
    //   state.user = user;
    // },
    logOut(state) {
      setSession();

      state.isLoggedIn = false;
      state.token = null;
      state.refreshToken = null;
      state.user = null;
    },

    updateUserData(state, action) {
      // console.log("in updateUserData");
      // console.log("in updateUserData state", state);
      // console.log("in updateUserData action", action);
      const { token, user, isLoggedIn, refreshToken } = action.payload;
      setSession(token, user?.selectedTenant?.id);

      state.isLoggedIn = isLoggedIn;
      state.token = token;
      state.refreshToken = refreshToken;
      state.user = user;
    },
    // chooseTenant(state, action) {
    //   const { token, user, refreshToken } = action.payload;
    //   setSession(token, user?.selectedTenant?.id);

    //   state.isLoggedIn = true;
    //   state.token = token;
    //   state.refreshToken = refreshToken;
    //   state.user = user;
    // },

    // setTenants(state, action) {
    //   const { token, user } = action.payload;
    //   setSession(token, user?.selectedTenant?.id);

    //   state.tenants = action.payload;
    // },

    // switchTenant(state, action) {
    //   setSession(state.token, action.payload?.id);

    //   state.user = {
    //     ...state.user,
    //     ...action.payload,
    //     selectedTenant: action.payload,
    //   };
    // },
    switchOrChooseTenant(state, action) {
      const { token, user, isLoggedIn, refreshToken } = action.payload;
      setSession(token, user?.selectedTenant?.id);

      state.isLoggedIn = isLoggedIn;
      state.token = token;
      state.refreshToken = refreshToken;
      state.user = user;
    },
    setTheSession(state, action) {
      const { token, user, isLoggedIn } = action.payload;
      setSession(token, user?.selectedTenant?.id);

      state.isLoggedIn = isLoggedIn;
      state.token = token;
      state.user = user;
    },
  },
});

export const {
  // logIn,
  logOut,
  updateUserData,
  chooseTenant,
  // setTenants,
  switchTenant,
  setTheSession,
  switchOrChooseTenant,
} = authSlice.actions;

export default authSlice.reducer;
