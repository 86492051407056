import { Avatar } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { blue } from "@mui/material/colors";

const CustomAvatar = ({ src }) => {
  const classes = makeStyles({
    avatar: {
      backgroundColor: blue[100],
      color: blue[600],
    },
  })();

  return <Avatar src={src} className={classes.avatar} />;
};

export default CustomAvatar;
