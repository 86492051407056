import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
const CustomBackdrop = () => {
  const backdrop = useSelector((state) => state.backdrop);
  return (
    <Backdrop style={{ zIndex: 1500 }} open={backdrop}>
      <CircularProgress />
    </Backdrop>
  );
};

export default CustomBackdrop;
