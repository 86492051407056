import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { MAIN_URL } from "../config/constants";

const PermissionGuard = ({ children, permissions }) => {
  const userPermissions = useSelector(
    (state) => state?.auth?.user?.permissions ?? []
  );

  // check if the user got this permission only if the route requires a permission
  const canAccessThisRoute = () => {
    if (permissions && permissions?.length > 0) {
      let result = 0;
      for (const i in permissions) {
        for (const j in userPermissions) {
          if (permissions[i] === userPermissions[j]) {
            result++;
          }
        }
      }
      return result;
    }

    return true;
  };

  const pathname = window?.location?.pathname;

  if (!canAccessThisRoute() && pathname !== MAIN_URL) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
};

export default PermissionGuard;
