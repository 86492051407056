import { lazy } from "react";
import permissions from "./permissions";
import AuthGuard from "gaurds/AuthGuard";
import GuestGuard from "gaurds/GuestGuard";
import { Redirect } from "react-router-dom";
import AdminLayout from "layouts/AdminLayout";

export const AdminRoutes = [
  // home
  {
    exact: true,
    path: "/home",
    title: "Home",
    component: lazy(() => import("pages/home/Home")),
  },
  // Reset password
  {
    exact: true,
    path: "/reset-password",
    title: "Reset Password",
    component: lazy(() => import("pages/reset-password/ResetPassword")),
  },
  // Inbound Orders
  {
    exact: true,
    path: "/inbound-orders",
    title: "Inbound Orders",
    permissions: [permissions.InboundView],
    component: lazy(() => import("pages/orders/pages/InboundOrder/pages")),
  },
  {
    exact: true,
    path: "/inbound-orders/add",
    title: "New Order",
    permissions: [permissions.InboundCreateOrder],
    component: lazy(() =>
      import("pages/orders/pages/InboundOrder/pages/receiveByOrder/add")
    ),
  },
  {
    exact: true,
    title: "Edit Inbound Order",
    path: "/inbound-orders/:id/edit",
    permissions: [permissions.InboundUpdateOrder],
    component: lazy(() =>
      import("pages/orders/pages/InboundOrder/pages/receiveByOrder/edit")
    ),
  },
  {
    exact: true,
    title: "Split Inbound Order",
    path: "/inbound-orders/:id/split",
    // permissions: [permissions.InboundUpdateOrder],
    component: lazy(() =>
      import("pages/orders/pages/InboundOrder/pages/receiveByOrder/split")
    ),
  },
  {
    exact: true,
    title: "Release to Quality",
    path: "/inbound-orders/:id/scan",
    permissions: [permissions.InboundReleaseToQuality],
    component: lazy(() => import("pages/orders/pages/InboundOrder/pages/scan")),
  },
  {
    exact: true,
    title: "Accept",
    path: "/inbound-orders/:id/scan",
    permissions: [permissions.InboundAccept],
    component: lazy(() => import("pages/orders/pages/InboundOrder/pages/scan")),
  },
  {
    exact: true,
    title: "Reject",
    path: "/inbound-orders/:id/scan",
    permissions: [permissions.InboundReject],
    component: lazy(() => import("pages/orders/pages/InboundOrder/pages/scan")),
  },
  {
    exact: true,
    title: "Blind Receive",
    path: "/inbound-orders/:id/scan",
    //TODO
    // permissions: [permissions.InboundAccept],
    component: lazy(() =>
      import("pages/orders/pages/InboundOrder/pages/blindReceive/scan")
    ),
  },
  {
    exact: true,
    title: "Blind Receive",
    path: "/inbound-orders/:id/add-aggregation",
    //TODO
    // permissions: [permissions.InboundAccept],
    component: lazy(() =>
      import("pages/orders/pages/InboundOrder/pages/blindReceive/aggregation")
    ),
  },
  {
    exact: true,
    title: "Adhoc Without Supplier",
    path: "/inbound-orders/adhoc-without-supplier",
    //TODO
    // permissions: [permissions.InboundAccept],
    component: lazy(() =>
      import("pages/orders/pages/InboundOrder/pages/adhoc/withoutSupplier")
    ),
  },
  {
    exact: true,
    title: "Add Comply Receive",
    path: "/inbound-orders/add-comply-receive",
    //TODO
    // permissions: [permissions.InboundAccept],
    component: lazy(() =>
      import(
        "pages/orders/pages/InboundOrder/pages/complyReceive/addComplyReceive"
      )
    ),
  },

  // Outbound Orders

  {
    exact: true,
    title: "Add Cancel Order",
    path: "/outbound-orders/add-cancel-order",
    //TODO
    // permissions: [permissions.InboundAccept],
    component: lazy(() =>
      import(
        "pages/orders/pages/OutboundOrder/pages/cancelShipout/addCancelOrder"
      )
    ),
  },
  {
    exact: true,
    path: "/outbound-orders",
    title: "Outbound Orders",
    permissions: [permissions.OutboundView],
    component: lazy(() => import("pages/orders/pages/OutboundOrder/pages")),
  },
  {
    exact: true,
    title: "New Order",
    path: "/outbound-orders/add",
    permissions: [permissions.OutboundCreateOrder],
    component: lazy(() =>
      import("pages/orders/pages/OutboundOrder/pages/shipOutOrders/add")
    ),
  },
  {
    exact: true,
    title: "Edit Outbound Order",
    path: "/outbound-orders/:id/edit",
    permissions: [permissions.OutboundUpdateOrder],
    component: lazy(() =>
      import("pages/orders/pages/OutboundOrder/pages/shipOutOrders/edit")
    ),
  },
  {
    exact: true,
    title: "Scan Outbound Order",
    path: "/outbound-orders/:id/scan",
    permissions: [permissions.OutboundCreateOrder],
    component: lazy(() =>
      import("pages/orders/pages/OutboundOrder/pages/scan")
    ),
  },
  //Serialization Orders
  // {
  //   exact: true,
  //   path: "/serialization",
  //   title: "Serialization",
  //   permissions: [permissions.SO_View],
  //   component: lazy(() => import("pages/plantMaster/Serialization")),
  // },
  // {
  //   exact: true,
  //   path: "/serialization/:id/Edit",
  //   title: "Edit Serialization Order",
  //   permissions: [permissions.SO_Edit],
  //   component: lazy(() => import("pages/plantMaster/EditSerializationOrder")),
  // },
  // {
  //   exact: true,
  //   title: "New Order",
  //   path: "/serialization/add",
  //   permissions: [permissions.SO_Add],
  //   component: lazy(() => import("pages/plantMaster/AddSerializationOrder")),
  // },
  // Move Orders
  {
    exact: true,
    path: "/move-orders",
    title: "Move Orders",
    permissions: [permissions.MO_View],
    component: lazy(() => import("pages/moveOrders/MoveOrders")),
  },
  {
    exact: true,
    title: "New Order",
    path: "/move-orders/Add",
    permissions: [permissions.MO_Add],
    component: lazy(() => import("pages/moveOrders/AddMoveOrder")),
  },
  // Files Management
  {
    exact: true,
    path: "/files",
    title: "Files",
    permissions: [permissions.InboundFileView],
    component: lazy(() => import("pages/files/Files")),
  },
  {
    exact: true,
    path: "/files/add",
    title: "New File",
    permissions: [permissions.InboundCreateInboundFile],
    component: lazy(() => import("pages/files/AddFile")),
  },
  // Machines
  {
    exact: true,
    path: "/machines",
    title: "Machines",
    permissions: [permissions.Machine_View],
    component: lazy(() => import("pages/machines/MachinesOverView")),
  },
  {
    exact: true,
    path: "/machines/add",
    title: "New Machine",
    permissions: [permissions.Machine_Add],
    component: lazy(() => import("pages/machines/AddMachine")),
  },
  {
    exact: true,
    title: "Edit Machine",
    path: "/machines/:id/Edit",
    permissions: [permissions.Machine_Edit],
    component: lazy(() => import("pages/machines/EditMachine")),
  },
  // Partners
  {
    exact: true,
    path: "/partners",
    title: "Partners",
    permissions: [permissions.PartnerView],
    component: lazy(() => import("pages/partners/Partners")),
  },
  {
    exact: true,
    path: "/partners/add",
    title: "Add Partner",
    permissions: [permissions.PartnerCreate],
    component: lazy(() => import("pages/partners/AddPartner")),
  },
  {
    exact: true,
    path: "/partners/:id/edit",
    title: "Edit Partner",
    permissions: [permissions.PartnerCreate],
    component: lazy(() => import("pages/partners/EditPartner")),
  },
  // products
  {
    exact: true,
    path: "/products",
    title: "Products",
    permissions: [permissions.ProductView],
    component: lazy(() => import("pages/products/Products")),
  },
  {
    exact: true,
    path: "/products/add",
    title: "Add Products",
    permissions: [permissions.ProductCreate],
    component: lazy(() => import("pages/products/AddProduct")),
  },
  {
    exact: true,
    path: "/products/:id/edit",
    title: "Edit Partner",
    permissions: [permissions.ProductCreate],
    component: lazy(() => import("pages/products/EditProduct")),
  },
  // skus
  {
    exact: true,
    path: "/skus",
    title: "SKUs",
    component: lazy(() => import("pages/skus/SKUs")),
  },
  {
    exact: true,
    path: "/skus/add",
    title: "Add SKU",
    component: lazy(() => import("pages/skus/AddSKU")),
  },
  // inventory
  {
    exact: true,
    path: "/mapping",
    title: "Mapping",
    component: lazy(() => import("pages/inventory/Mapping")),
  },
  {
    exact: true,
    path: "/mapping/add",
    title: "Add Mapping",
    component: lazy(() => import("pages/inventory/AddMapping")),
  },
  // reports
  {
    exact: true,
    path: "/availability-report",
    title: "Availability Report",
    permissions: [permissions.ViewProductAvailabilityReport],
    component: lazy(() => import("pages/reports/AvailabilityReport")),
  },
  {
    exact: true,
    path: "/auditTrail-report",
    title: "Audit Trail Report",
    permissions: [permissions.ViewProductAvailabilityReport],
    component: lazy(() => import("pages/reports/history/AuditTrailReport")),
  },
  {
    exact: true,
    path: "/supplier-report",
    title: "Supplier Report",
    permissions: [permissions.ViewProductBySupplierReport],
    component: lazy(() => import("pages/reports/SupplierReport")),
  },
  {
    exact: true,
    title: "Inventory",
    path: "/quantity-report",
    permissions: [permissions.ViewProductQuantityReport],
    component: lazy(() => import("pages/reports/QuantityReport")),
  },
  {
    exact: true,
    path: "/decanting-report",
    title: "Decanting Report",
    permissions: [permissions.ViewProductQuantityReport],
    component: lazy(() => import("pages/reports/DecantingReports")),
  },
  // {
  //   exact: true,
  //   title: "Batch Availability",
  //   path: "/batch-availability",
  //   permissions: [permissions.ViewProductQuantityReport],
  //   component: lazy(() => import("pages/reports/BatchReport")),
  // },
  // {
  //   exact: true,
  //   path: "/batch-status",
  //   title: "Batch Status",
  //   permissions: [permissions.ViewProductQuantityReport],
  //   component: lazy(() => import("pages/reports/BatchStatus")),
  // },
  {
    exact: true,
    path: "/non-serialized",
    title: "Non-Serialized Status",
    permissions: [permissions.ViewProductQuantityReport],
    component: lazy(() => import("pages/reports/NonSerializedStatus")),
  },
  // roles
  {
    exact: true,
    path: "/roles",
    title: "Roles",
    permissions: [permissions.UserManagement],
    component: lazy(() => import("pages/roles/Roles")),
  },
  {
    exact: true,
    path: "/roles/add",
    title: "Add Role",
    permissions: [permissions.UserManagement],
    component: lazy(() => import("pages/roles/AddRole")),
  },
  {
    exact: true,
    path: "/roles/:id/Edit",
    title: "Edit Role",
    permissions: [permissions.UserManagement],
    component: lazy(() => import("pages/roles/EditRole")),
  },
  {
    exact: true,
    path: "/roles/:id/duplicate",
    title: "Duplicate Role",
    permissions: [permissions.UserManagement],
    component: lazy(() => import("pages/roles/DuplicateRole")),
  },
  // users
  {
    exact: true,
    path: "/users",
    title: "Users",
    permissions: [permissions.UserManagement],
    component: lazy(() => import("pages/users/Users")),
  },
  {
    exact: true,
    path: "/users/add",
    title: "Add User",
    permissions: [permissions.UserManagement],
    component: lazy(() => import("pages/users/AddUser")),
  },
  {
    exact: true,
    path: "/users/:id/Edit",
    title: "Edit User",
    permissions: [permissions.UserManagement],
    component: lazy(() => import("pages/users/EditUser")),
  },
  {
    exact: true,
    path: "/point-of-sale",
    title: "Point of Sale",
    permissions: [permissions.POS_Sell],
    component: lazy(() => import("pages/pointOfSale/PointOfSale")),
  },
  //Verify Status page
  {
    exact: true,
    path: "/verify-status",
    title: "Verify Status",
    permissions: [permissions.Verify_Status],
    component: lazy(() => import("pages/verifyStatus/VerifyStatus")),
  },
  // Verification page
  {
    exact: true,
    path: "/verification",
    title: "Verification",
    permissions: [permissions.ItemVerification],
    component: lazy(() => import("pages/verification/Verification")),
  },
  {
    exact: true,
    path: "/decommission",
    title: "Decommission",
    permissions: [permissions.DecomissionView],
    component: lazy(() => import("pages/decommission/Decommission")),
  },
  {
    exact: true,
    path: "/activate",
    title: "Activate",
    permissions: [permissions.Activate],
    component: lazy(() => import("pages/activate/Activate")),
  },
  //Pack
  {
    exact: true,
    path: "/pack",
    title: "Pack",
    permissions: [permissions.Disaggregation],
    component: lazy(() => import("pages/pack/Pack")),
  },
  {
    exact: true,
    path: "/pack/:id/Order Pack",
    title: "Order Pack",
    permissions: [permissions.Disaggregation],
    component: lazy(() =>
      import("pages/pack/components/byOrder/PackScanOutbound")
    ),
  },
  //Unpack
  {
    exact: true,
    path: "/unpack",
    title: "Unpack",
    permissions: [permissions.Disaggregation],
    component: lazy(() => import("pages/unpack/Unpack")),
  },
  //Permit Number
  {
    exact: true,
    path: "/permit-number",
    title: "Permit Number",
    // permissions: [permissions.Disaggregation],
    component: lazy(() => import("pages/permitNumber")),
  },
  {
    exact: true,
    title: "Add",
    path: "/permit-number/add",
    // permissions: [permissions.Disaggregation],
    component: lazy(() => import("pages/permitNumber/addEdit")),
  },
  {
    exact: true,
    title: "Edit",
    path: "/permit-number/:id/edit",
    // permissions: [permissions.Disaggregation],
    component: lazy(() => import("pages/permitNumber/addEdit")),
  },
  //Serial Generator
  {
    exact: true,
    title: "Serial Generator",
    path: "/serial-generator",
    // permissions: [permissions.Disaggregation],
    component: lazy(() => import("pages/serialGenerator/SerialGenerator")),
  },
  //Cycle Count
  {
    exact: true,
    title: "Cycle Count",
    path: "/cycle-count",
    permissions: [permissions.CycleCount_View],
    component: lazy(() => import("pages/cycleCount/CycleCount")),
  },
  {
    exact: true,
    title: "Create Cycle Count",
    path: "/cycle-count/create",
    permissions: [permissions.CycleCount_Create],
    component: lazy(() => import("pages/cycleCount/CreateCycleCount")),
  },
  {
    exact: true,
    title: "Update Cycle Count",
    path: "/cycle-count/:id/Update",
    permissions: [permissions.CycleCount_Create],
    component: lazy(() => import("pages/cycleCount/UpdateCycleCount")),
  },
  {
    exact: true,
    title: "Scan Cycle Count",
    path: "/cycle-count/:id/Scan",
    permissions: [permissions.CycleCount_Create],
    component: lazy(() => import("pages/cycleCount/ScanCycleCount")),
  },
  {
    exact: true,
    title: "Cycle Count Report",
    path: "/cycle-count/:id/report",
    permissions: [permissions.CycleCount_View],
    component: lazy(() => import("pages/cycleCount/ReportCycleCount")),
  },
  {
    exact: true,
    title: "Adhoc Cycle Count",
    path: "/cycle-count/scan-adhoc",
    permissions: [permissions.AdhocCycleCount_Create],
    component: lazy(() => import("pages/cycleCount/AdhocCycleCount")),
  },
  {
    exact: true,
    title: "Admin Panel",
    path: "/admin-panel",
    //TODO
    // permissions: [permissions.Disaggregation],
    component: lazy(() => import("pages/admin-panel/AdminPanel")),
  },
  {
    exact: true,
    title: "Tenant Settings",
    path: "/admin-panel/:id/tenant-settings",
    //TODO
    // permissions: [permissions.Disaggregation],
    component: lazy(() => import("pages/admin-panel/TenantSettings/index.js")),
  },
  // Error Page
  {
    path: "*",
    exact: true,
    component: () => <Redirect to={"/404"} />,
  },
];

const routes = [
  // Error Page
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("pages/error/Error")),
  },
  // Login Page
  {
    exact: true,
    guard: GuestGuard,
    path: "/",
    component: lazy(() => import("pages/login/Login")),
  },
  // Dashboard Layout (Logged in routes)
  {
    path: "*",
    guard: AuthGuard,
    layout: AdminLayout,
    routes: AdminRoutes,
  },
];

export default routes;
