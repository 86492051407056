import { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import PermissionGuard from "gaurds/PermissionGuard";
import {Box, CircularProgress, Fade} from "@mui/material";
import {COMPANY_NAME} from "../config/constants";
import originLogo from "../config/assets/origin-original-logo.png";

const Div = (props) => <div {...props}></div>;
const Spinner = () => (

    <main
        style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}
    >

            <Fade in={true} timeout={2000}>
                <Box
                    component='img'
                    alt={COMPANY_NAME}
                    sx={{
                        width: {xs: '20%'},
                    }}
                    src={originLogo}
                /></Fade>
        {/*<Box>*/}
        {/*  <CircularProgress size={75} disableShrink />*/}
        {/*</Box>*/}
    </main>
);

const RoutesBuilder = ({routes = []}) => {
    return (
        <Suspense fallback={<Spinner/>}>
            <Switch>
                {routes.map((route, i) => {
                    const Guard = route.guard || Div;
                    const Layout = route.layout || Div;
                    const Component = route.component;

                    return (
                        <Route
                            key={i}
                            path={route.path}
                            exact={route.exact}
                            component={(props) => (
                                <Guard>
                                    <Layout history={props.history}>
                                        {route.routes ? (
                                            <RoutesBuilder routes={route.routes}/>
                                        ) : (
                                            <PermissionGuard permissions={route.permissions}>
                                            <Component {...props} />
                      </PermissionGuard>
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

export default RoutesBuilder;
